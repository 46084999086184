<template>
  <header-menu :menus="menus" ref="menuBar"></header-menu>
  <div class="flex w-100">
      <router-view></router-view>
  </div>
  <footer-menu></footer-menu>
</template>
<script setup>
// import {Modal} from '@/components'
import { HeaderMenu, FooterMenu } from '@/components'
import {menus} from '@/lib/utils'
import {onMounted} from 'vue'

// const menuItems = menus
onMounted(() => {
    //$refs.menuBar.goTo(menus[2])
})
</script>